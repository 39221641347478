<template>
    <a class="btn-link form-control-plaintext text-blue" href="javascript:;" @click='showForm = true'>
        Đổi mật khẩu
        <b-modal v-model="showForm" title="Đổi mật khẩu" @ok="save" @close="close" @cancel="close" :no-close-on-backdrop="true" :no-enforce-focus="true">
            <form @submit.prevent="save" autocomplete="off">
                <form-input label="Mật khẩu hiện tại" type="password" :model="formModel" attribute="oldpass" :errors="formErrors" placeholder="Nhập mật khẩu hiện tại của bạn"></form-input>
                <form-input label="Mật khẩu mới" type="password" :model="formModel" attribute="newpass" :errors="formErrors" placeholder="Nhập mật khẩu mới của bạn"></form-input>
                <form-input label="Xác nhận mật khẩu mới" type="password" :model="formModel" attribute="repeatnewpass" :errors="formErrors" placeholder="Nhập lại mật khẩu mới của bạn"></form-input>
            </form>
            <template v-slot:modal-footer>
                <b-button variant="secondary" class="float-right" @click="close">Huỷ</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="save">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    Thay đổi
                </b-button>
            </template>
        </b-modal>
    </a>
</template>

<script>

    export default {
        data: function () {
            return {
                formModel: {},
                saveLoading: false,
                showForm: false,
                formErrors: {},
            };
        },
        methods: {
            save() {
                this.saveLoading = true;
                this.$service({url: '/site/profile-pwd', method: 'put', data: this.formModel}).then(response => {
                    if (response.data) {
                        this.$swal({
                            text: "Đổi mật khẩu thành công",
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    } else {
                        this.$swal({
                            text: "Đổi mật khẩu thất bại",
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    }
                    this.showForm = false;
                }).catch(error => {
                    if (error.response.data.msg) {
                        this.$swal({
                            text: error.response.data.msg,
                            icon: 'error'
                        });
                        this.showForm = false;
                    } else {
                        this.formErrors = error.response.data;
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            },
            close() {
                this.showForm = false;
            }
        }
    }
</script>