<template>
    <div class="card title">
        <div class="card-body title-body p-3">
            <h3 class="tile-title is-line mb-2">
                Thông tin cá nhân
                <span class="btn-add float-right pr-2">
                    <i class="icon24-edit-circle-primary pointer" :disabled="listLoading" @click="edit" v-if="!showForm"></i>
                </span>
            </h3>
            <div class="row">
                <div class="col-12">
                    <g-loading v-if="firstLoading" />
                    <div v-else-if="listLoading" class="text-center text-primary">
                        <b-spinner></b-spinner>
                    </div>
                    <div v-else-if="!showForm" class="form-horizontal is-label-right">
                        <div class="form-group row">
                            <label class="col-form-label col-md-3">Tên</label>
                            <div class="col-md-6">
                                <span @click="edit" class="form-control-plaintext">{{ formModel.name }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-form-label col-md-3">Mật khẩu</label>
                            <div class="col-md-6">
                                <pwd/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-form-label col-md-3">Email</label>
                            <div class="col-md-6">
                                <span @click="edit" class="form-control-plaintext">{{ formModel.email }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-form-label col-md-3">Điện thoại</label>
                            <div class="col-md-6">
                                <span @click="edit" class="form-control-plaintext">{{ formModel.mobile }}</span>
                            </div>
                        </div>
                    </div>
                    <form class="form-horizontal is-label-right" v-else @submit.prevent="save" autocomplete="off">
                        <div class="form-group row mb-1 mt-2">
                            <label class="col-md-3 col-form-label">Tên</label>
                            <div class="col-md-6">
                                <form-input type="text" :model="formModel" attribute="name" :errors="formErrors"></form-input>
                            </div>
                        </div>
                        <div class="form-group row mb-1">
                            <label class="col-md-3 col-form-label">Email</label>
                            <div class="col-md-6 pb-1">
                                <form-input type="text" :model="formModel" attribute="email" :errors="formErrors"></form-input>
                            </div>
                        </div>
                        <div class="form-group row mb-1">
                            <label class="col-md-3 col-form-label">Điện thoại</label>
                            <div class="col-md-6">
                                <form-input type="text" :model="formModel" attribute="mobile" :errors="formErrors"></form-input>
                            </div>
                        </div>
                        <div class="form-group row mb-1">
                            <div class="col-md-6 offset-md-3">
                                <button class="btn btn-secondary mr-2" @click="cancel">Huỷ</button>
                                <button class="btn btn-primary" type="submit" :disabled="saveLoading">
                                    <b-spinner v-if="saveLoading" small></b-spinner>
                                    <span v-else>Lưu lại</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Pwd from './Pwd';

    export default {
        components: {Pwd},
        data: function () {
            return {
                formModel: {},
                listLoading: false,
                saveLoading: false,
                firstLoading: true,
                showForm: false,
                passwordForm: false,
                formErrors: {},
                errors: {}
            };
        },
        methods: {
            load() {
                let loadingDelay = _.delay(() => {
                    this.listLoading = true;
                }, 250);

                this.$service.get('/site/profile').then(response => {
                    this.formModel = response.data;
                    this.firstLoading = false;
                }).finally(() => {
                    this.listLoading = false;
                    clearTimeout(loadingDelay);
                });
            },
            edit() {
                this.load();
                this.showForm = true;
            },
            save() {
                this.saveLoading = true;
                this.$service({url: '/site/profile-update', method: 'put', data: this.formModel}).then(response => {
                    if (response.data) {
                        this.$service.get('/site/profile').then(response => {
                            this.$store.commit('user', response.data);
                        });
                        this.$swal({
                            text: "Cập nhật thành công",
                            icon: 'success',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    } else {
                        this.$swal({
                            text: "Cập nhật thất bại",
                            icon: 'error',
                            toast: true,
                            position: 'bottom-end',
                            timer: 5000
                        });
                    }
                    this.load();
                    this.showForm = false;
                }).catch(error => {
                    if (error.response.data.msg) {
                        this.$swal({
                            text: error.response.data.msg,
                            icon: 'error'
                        });
                        this.showForm = false;
                    } else {
                        this.formErrors = error.response.data;
                    }
                }).finally(() => {
                    this.saveLoading = false;
                });
            },
            cancel() {
                this.load();
                this.showForm = false;
            }
        },
        mounted() {
            this.firstLoading = true;
            this.load();
        }
    }
</script>